import React from "react";
import "../../styles/SupportPage.css";

const HeroSection = () => {
  return (
    <div className="hero-section">
      <div className="hero-content">
        <h1 className="hero-heading">Customer Support for Seamless Mapping</h1>
        <h2 className="hero-subheading-support">
          Our team ensures your Fribe Maps portal operates seamlessly by swiftly
          addressing issues. We provide prompt assistance, thorough
          troubleshooting, and regular updates for a smooth and reliable mapping
          experience.
        </h2>
      </div>
    </div>
  );
};

export default HeroSection;
